<template>
  <div>
    <v-card-text :style="`height: ${height}px; overflow: auto`" class="pa-0">
      <a-table-f-api
        ref="table"
        :api="m.api"
        :model="model"
        :searchable="false"
        :defaults="{
          filters: { lead_id: id, user_id: getAccess(`leads.viewAll`) || id ? undefined : $root.profile.id },
          sort: [
            { key: 'status', order: 'ASC' },
            { key: 'id', order: 'DESC' },
          ],
          paramName: 'leadActions',
        }"
        @loadData="$emit('loadData', $event)"
        @click="onClickRow($event)"
      >
        <template v-slot:item.actions="{ row }">
          <div class="d-flex">
            <v-btn
              v-if="row.status == 1 && getAccess('leads.actions.create')"
              x-small
              fab
              class="ma-0 ml-0"
              color="green"
              title="Выполнить"
              @click.stop="$emit('action', { task: row, type: 'done' })"
            >
              <v-icon small>fas fa-check</v-icon>
            </v-btn>
            <v-btn
              v-if="row.status == 1 && false"
              x-small
              fab
              class="ma-0 ml-1"
              color="red"
              title="Отменить"
              @click.stop="$emit('action', { task: row, type: 'cancel' })"
            >
              <v-icon small>fas fa-times</v-icon>
            </v-btn>
          </div>
        </template>
      </a-table-f-api>
    </v-card-text>
  </div>
</template>

<script>
import leads from "@/mixins/leads";
import { getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, genModel, leads],
  components: {},
  props: {
    id: Number,
    data: Object,
    height: { type: Number, default: 300 },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.leads.tasks,
      task_: null,
    };
  },
  created() {},
  watch: {
    showDialog(v) {
      if (!v) {
        this.$refs.table.updateData();
      }
    },
  },
  computed: {
    url() {
      return this.m.api;
    },
    model() {
      this.makeTasks();
      let model = this.getModelList(this.m);
      model.find((m) => m.name == "type").options = this.task_ || [];
      return model;
    },
  },
  methods: {
    async makeTasks(f) {
      if (!this.task_) this.task_ = await this.getTasks(f);
      return this.task_;
    },
    onClickRow(d) {
      if (d.field.name == "actions") {
      } else this.$emit("edit", d.row.id);
      return;
      if (d.field?.isMenu) {
        this.showPopupMenu(d.field.name, d.row, d.event, d);
        return;
      }
      this.showEditDialogGoodsFunc(d.row.id);
    },
  },
};
</script>
